import { Stomp } from "@stomp/stompjs";
import SockJS from "sockjs-client";
import { CHAT_BASE_URL } from '../config';

//client 객체 생성 및 서버주소 입력
//const sock = new SockJs(url); //endpoint
//stomp로 감싸기

let stompClient = null;
let Sock = null;

const url = CHAT_BASE_URL;

export const connectWebSocket = (token, onConnected, connectError) => {
    try {
        Sock = new SockJS(url);
        stompClient = Stomp.over(Sock);

        stompClient.connect({
            "Authorization" : `Bearer ${token}`
        }, onConnected, connectError);

        // default 설정으로는 console에 STOMP frame이 모두 노출
        // log가 뜨지 않게 한다.
        stompClient.debug = () => {};

        stompClient.onWebSocketClose = (e) => {
            // 서버가 닫혀있을 경우 e.code: 1002
            if(e.code === 1002 && e.reason === "Cannot connect to server") {
                alert('서버에 연결할 수 없습니다.');
                window.location.href = '/';
            }

            // Tip: Query Param 으로 토큰을 보낼 때,
            // Token 예외일 경우 e.code: 2000
        };

    } catch (error) {
        console.log("연결 시도 중 예외 발생: ", error);
    }
    return stompClient;
};

export const disconnectWebSocket = () => {
    if (stompClient) {
        stompClient.deactivate();
    }
    if (Sock) {
        Sock.close();
    }
};

export const sendMessage = (receiverName, status, data, myName, roomId) => {
    let chatMessage = {
        senderName: myName,
        receiverName: receiverName,
        status: status,
        roomId: roomId,
        data: data,
    };

    if (receiverName === "") {
        stompClient.send(`/chatroom/${roomId}`, {}, JSON.stringify(chatMessage));
        //stompClient.send(`/app/message/${roomId}`, {}, JSON.stringify(chatMessage));
    } else {
        // Controller를 경유한다.
        //stompClient.send("/app/private-message", {}, JSON.stringify(chatMessage));
        stompClient.send(`/user/${receiverName}/private`, {}, JSON.stringify(chatMessage));
    }
};
