import { useState, useEffect, useRef } from "react";
import Video from './video/Video';
import {useParams} from 'react-router-dom';
import Chat from './Chat';
import "../css/ChatRoom.css"
import { useLocation } from 'react-router-dom'
import {connectWebSocket, disconnectWebSocket, sendMessage} from './WebSocket'

let stompClient = null;
let localStream = null;
let myName='';
let mic_switch = true;
let video_switch = true;
let pcs = {};

const ChatRoom  = () => {
    const [users, setUsers] = useState([]);
    const [chats, setChats] = useState([]);
    const [message, setMessage] = useState('');
    const scrollRef = useRef(null); // 스크롤을 맨 아래로 이동시키기 위한 ref
    let localVideo = useRef();
    const pcConfig = {
    iceServers: [
        {
        urls: "stun:stun.l.google.com:19302",
        },
    ],
    };
    let offerType = { offerToReceiveVideo: true, offerToReceiveAudio: true };
    const { roomId } = useParams();
    const { state } = useLocation();

    //창을 끄는 경우, 나가기 버튼을 누르는 경우
    window.onbeforeunload = ()=> {
      if(Object.keys(pcs).length > 0) sendMessage('', 'LEAVE', '', myName, roomId);
    }

    const go =()=>{
      window.location.href = "/"; // redirect
    }
    
    useEffect(() => {
      if(state == null){
        window.location.href = "/login";
        alert("로그인 후 이용해주세요.")
      }

      //유저 미디어 받아오기.
      navigator.mediaDevices.getUserMedia({
        audio: true,
        video: {
          optional: [
            { googNoiseReduction: true }, // Likely removes the noise in the captured video stream at the expense of computational effort.
            { facingMode: 'user' }, // Select the front/user facing camera or the rear/environment facing camera if available (on Phone)
          ]
        }
      }).then(function(stream){
        if (localVideo.current) localVideo.current.srcObject=stream;
        localStream=stream;
        console.log("getUserMedia");

        //미디어 연결 했으면 WebSocket 연결
        stompClient = connectWebSocket(state.token, onConnected, connectError);
      }).catch((e)=>{
        console.log('getUserMedia() error: ' + e);
        alert("미디어 권한이 없습니다.");
        window.location.href = '/';
      });

      //RTCPeerConnection 생성
      const createPeerConnection = (senderID, myName, localStream) => {
        let pc = new RTCPeerConnection(pcConfig);
        console.log("createPeerConnection")
        pcs[senderID] = pc;
    
        pc.onicecandidate = (e) => {
          console.log("icecandidate event: ", e);
          if (e.candidate!==null) {
            //candidate 전송
            console.log("CANDIDATE 전송")
            sendMessage(senderID, "CANDIDATE", e.candidate, myName, roomId)
          } else {
            console.log("End of candidates.");
          }
        };
    
        pc.ontrack = (ev) => {
          console.log("ontrack success: " + ev);
          setUsers((users) => users.filter((element) => element.id !== senderID));
          setUsers((users) =>
            users.concat({
              id: senderID,
              stream: ev.streams[0],
            })
          );
        };
    
        if (localStream) {
          for (const track of localStream.getTracks()) {
            pc.addTrack(track, localStream);
          }
        }
    
        return pc;
      };

      const onConnected = (e) => {
        //연결되면 닉네임 받아오기
        myName = e.headers["user-name"]

        //구독하기
        stompClient.subscribe("/chatroom/" + roomId, onMessageReceived);
        stompClient.subscribe("/user/private", onPrivateMessage);

        //입장 메세지 보내기
        sendMessage("", "JOIN", "", myName, roomId);
      };

      const connectError = (e) =>{
        alert("연결 실패. 재입장 해주세요.");
        window.location.href = '/';
      }

      const onPrivateMessage = (payload) => {
          let payloadData = JSON.parse(payload.body);
          let senderID = payloadData.senderName;
          let pc = pcs[senderID];

            // eslint-disable-next-line default-case
            switch (payloadData.status) {
              case "OFFER":
                console.log('get offer: ');
                createPeerConnection(senderID, myName, localStream);
                pc=pcs[senderID]
                if (pc) {
                  pc.setRemoteDescription(new RTCSessionDescription(payloadData.data))
                  .then(() => {
                    console.log('setRemoteDescription');
                    pc.createAnswer(offerType)
                      .then(sdp => {
                        pc.setLocalDescription(new RTCSessionDescription(sdp));
                        console.log("send Answer");
                        sendMessage(senderID, "ANSWER", sdp, myName, roomId)
                      })
                      .catch(e => {
                        console.log(e);
                      })
                  })
                }else{console.log("ANSWER 불가! pc:" +pc)}
                break;
              case "CANDIDATE":
                console.log('get candidate');
                if (pc) {
                  pc.addIceCandidate(new RTCIceCandidate(payloadData.data))
                  .then(() => {
                    console.log('addIceCandidate');
                  }).catch(e => {
                    console.log("Failure during addIceCandidate(): " + e.name);
                  });
                }
                break;
                case "ANSWER":
                  console.log('get answer');
                  if (pc) {
                    pc.setRemoteDescription(new RTCSessionDescription(payloadData.data));
                  }
            }
      };
    
      const onMessageReceived = (payload) => {
          console.log("onMessageReceived");
          let payloadData = JSON.parse(payload.body);
          let senderID = payloadData.senderName;
          let pc = pcs[senderID];
          // eslint-disable-next-line default-case
          switch (payloadData.status) {
            case "JOIN":
              if(senderID!==myName){
              console.log("JOIN");
              //pc생성
              createPeerConnection(senderID, myName, localStream)

              pc = pcs[senderID];
      
              if (pc) {
                pc.createOffer(offerType)
                  .then((sdp) => {
                    console.log("offer");
                    pc.setLocalDescription(new RTCSessionDescription(sdp));
                    sendMessage(senderID, "OFFER", sdp, myName, roomId)
                  })
                  .catch((e) => {
                    console.log(e);
                  });
              }
            }
              break;
            case "MESSAGE":
              break;
            case "LEAVE":
              console.log("LEAVE")
              if (pcs[senderID]) pcs[senderID].close();
              delete pcs[senderID];
              setUsers(users => users.filter(element => element.id !== senderID));
              break;
          }

          setChats((chats) =>
          chats.concat({
            name: senderID,
            status: payloadData.status,
            data: payloadData.data,
          })
        );

      };  

      //뒤로가기
      return () => {
        if(Object.keys(pcs).length > 0) sendMessage('', 'LEAVE', '', myName, roomId);
        disconnectWebSocket();

        // localStream은 getUserMedia()를 통해 받은 MediaStream 객체입니다.
        if (localStream) {
          // 모든 트랙(비디오와 오디오)을 가져와서 각각을 종료합니다.
          localStream.getTracks().forEach(track => {
            track.stop(); // 트랙 종료
          });
        }
      }

    }, []);

  useEffect(()=>{
    // 새로운 메시지가 추가될 때마다 스크롤을 맨 아래로 이동
    scrollRef.current.scrollTop = scrollRef.current.scrollHeight;
  },[chats])

  const handleSendMessage = () => {
    if (message.trim() !== '') {
      sendMessage('', 'MESSAGE', message, myName, roomId)
      setMessage('');
    }
  };

  const switchVideo = (e) => {
    e.stopPropagation();
    if(localStream != null && localStream.getVideoTracks().length > 0){
      video_switch = !video_switch;
      
      localStream.getVideoTracks()[0].enabled = video_switch;
  
      const button = document.getElementById("videoButton");
      if(!video_switch){
        button.style.backgroundColor='#808080';
      }else{button.style.backgroundColor='#FFFFFF';}
  
    }
  
  }
  
  const switchMic = (e) => {
    e.stopPropagation();
    if(localStream != null && localStream.getAudioTracks().length > 0){
      mic_switch = !mic_switch;
  
      localStream.getAudioTracks()[0].enabled = mic_switch;
      const button = document.getElementById("micButton");
      if(!mic_switch){
        button.style.backgroundColor='#808080';
      }else{button.style.backgroundColor='#FFFFFF';}
    }
  }

  return (
        <div>
          <div>
            <button id='videoButton' style={{backgroundColor:'#FFFFFF', margin:'0', padding:'0'}} type='button' onClick={switchVideo}>카메라</button>
            <button id='micButton' style={{backgroundColor:'#FFFFFF', margin:'0', padding:'0'}} type='button' onClick={switchMic}>마이크</button>
            <button onClick={go}>나가기</button>
          </div>
          <div className='box'>
            <div className="left-container">
              <div className="video-box">
                <video className='video-component'
                  muted
                  ref={localVideo}
                  autoPlay playsInline>
                </video>
                <div style={{height: "5%", display: 'block'}}>{myName}</div>
              </div>
              {users.map((user, index)=>{
                return (
                  <Video key={index} name={user.id} stream={user.stream}>
                  </Video>
                );
              })}          
          </div>
          <div className="right-container">
            <div className="chat-container">
            <p>채팅창</p>
              <div className="messages" ref={scrollRef}>
                {chats.map((chat, index) => (
                <Chat key={index} chat={chat} myName={myName}></Chat>))}
              </div>
              <div className="input-container">
                <input
                type="text"
                placeholder="메시지 입력..."
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                />
                <button onClick={handleSendMessage}>전송</button>
              </div>
            </div>
          </div>
        </div>      
      </div>
  );
};

export default ChatRoom;
