import React from 'react';
import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import createAPI from '../service/ApiService';

const ACCESS_TOKEN = "ACCESS_TOKEN";

const Header = () => {

	const [isLoggedIn, setIsLoggedIn] = useState(false);


	const logout = async () => {
		try {
			await createAPI().post('/member/logout');
		} catch (error) {}
		setIsLoggedIn(false)
		localStorage.removeItem(ACCESS_TOKEN);
		window.location.href = "/";
	  };

	  useEffect(() => {
        // 로컬 스토리지에서 ACCESS TOKEN 가져오기
        const accessToken = localStorage.getItem("ACCESS_TOKEN");
		setIsLoggedIn(!!accessToken);
    }, []); // 빈 의존성 배열을 사용하여 마운트될 때 한 번만 실행


    return (
		<Navbar expand="lg" className="bg-body-tertiary">
		<Container>
		  <Navbar.Brand href="/">취미공유</Navbar.Brand>
		  <Navbar.Toggle aria-controls="basic-navbar-nav" />
		  <Navbar.Collapse id="basic-navbar-nav">
			<Nav className="me-auto">
			  <Nav.Link href="/">홈</Nav.Link>
			  {isLoggedIn ? (
				<Nav.Link onClick={logout}>로그아웃</Nav.Link>
      			) : (
				<Nav.Link href="/login">로그인</Nav.Link>
      			)}
	  			{isLoggedIn ? (
				<Nav.Link href="/createRoom">방생성</Nav.Link>
      			) : (
					<Nav.Link href="/signup">회원가입</Nav.Link>
      			)}
			</Nav>
		  </Navbar.Collapse>
		</Container>
	  </Navbar>
    );
}

export default Header;