import React from 'react';
import { Card,Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import createAPI from '../service/ApiService';

const Room = ({room}) => {

    const navigate = useNavigate();
	const accessToken = localStorage.getItem("ACCESS_TOKEN");

    const handleOpenRoom = async ()=>{
		// 로그인 여부 확인
		if (!accessToken) {
			alert("로그인 후 이용해주세요.");
			return;
		}
		
        try {
			const response = await createAPI().get('/member/check');
			navigate(`/chatroom/${room.id}`, {state: {token: response.data.token}});
		}catch(error) {
			if (error.response && error.response.status !== 401) {
				alert("네트워크 오류가 발생했습니다.");
			}
		}
	}

	const handleDeleteRoom = async ()=>{
		// 로그인 여부 확인
		if (!accessToken) {
			alert("로그인 후 이용해주세요.");
			return;
		}

        try {
			await createAPI().delete(`/room/${room.id}`);
			window.location.href = "/";
		} catch(error){
			if(error.response.status === 403) {
				alert("삭제 권한이 없습니다.");
			}
		}
	}


	return (
		<Card>
			<Card.Body>
				<Card.Title>{room.roomName}</Card.Title>
                <Card.Text>{room.roomDetail}</Card.Text>
                <Button variant="primary" onClick={handleOpenRoom}>입장하기</Button>
				<Button variant="dark" onClick={handleDeleteRoom}>삭제하기</Button>
			</Card.Body>
		</Card>
	);
};

export default Room;