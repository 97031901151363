// src/components/Signup.js

import React from 'react';
import createAPI from '../service/ApiService';

const SignUp = () => {

  const handleSubmit = async (event) => {
    event.preventDefault();
    // 여기에서 회원가입 로직을 구현합니다.
    
    // 오브젝트에서 form에 저장된 데이터를 맵의 형태로 바꿔줌.
    const data = new FormData(event.target);

    const userDTO = {
      name: data.get("name"),
      nickname: data.get("nickname"),
      password: data.get("password")
    }

    // 2-10자 글자수 검증
    if (userDTO.name.length < 2 || userDTO.name.length > 10) {
      alert("이름은 2자에서 10자 사이로 입력해 주세요.");
      return;
    }
    // 2-10자 글자수 검증
    if (userDTO.nickname.length < 2 || userDTO.nickname.length > 10) {
      alert("닉네임은 2자에서 10자 사이로 입력해 주세요.");
      return;
    }
    // 2-10자 글자수 검증
    if (userDTO.password.length < 5) {
      alert("비밀번호는 5자 이상 입력해 주세요.");
      return;
    }

    try {
      // createAPI를 사용하여 로그인 API 호출
      await createAPI().post('/member/signup', userDTO);
      window.location.href = "/login"; // redirect
    } catch (error) {
      // 오류 처리
      if(error.response && error.response.status === 409) {
        console.error('중복된 닉네임 입니다.');
        alert("중복 닉네임 입니다.");
      }else{
        alert(error.message);
      }
    }
  };

    return (
      <div>
        <h2>회원가입</h2>
        <form onSubmit={handleSubmit}>
          <div>
            <label htmlFor="name">이름:</label>
            <input
              type="text"
              id="name"
              name="name"
              required
            />
          </div>
          <div>
            <label htmlFor="nickname">닉네임:</label>
            <input
              type="text"
              id="nickname"
              name="nickname"
              required
            />
          </div>
          <div>
            <label htmlFor="password">비밀번호:</label>
            <input
              type="password"
              id="password"
              name="password"
              required
            />
          </div>
          <button type="submit">가입하기</button>
        </form>
      </div>
    );
}

export default SignUp;
