import axios from "axios";
import { API_BASE_URL } from '../config';

const ACCESS_TOKEN = "ACCESS_TOKEN";

// Axios 인스턴스를 생성하는 함수
function createAPI() {
  // 로컬 스토리지에서 ACCESS TOKEN 가져오기
  const accessToken = localStorage.getItem(ACCESS_TOKEN);
  
  // 기본 헤더 설정
  let headers = {
    "Content-Type": "application/json",
  };

  // 액세스 토큰이 존재하는 경우, Authorization 헤더 추가
  if (accessToken) {
    headers["Authorization"] = `Bearer ${accessToken}`;
  }

  // Axios 인스턴스 생성
  const API = axios.create({
    baseURL: API_BASE_URL, // https://{도메인}/api
    timeout: 2000,
    headers,
    withCredentials: true,
  });

// 응답 인터셉터 추가(status 401:인증 예외 처리)
// 10001: 토큰 만료로 인한 재발급 처리
// 10002: 토큰 발급 불가, 재로그인 처리
API.interceptors.response.use(
  (response) => {
    return response;  // 응답이 성공적일 때는 그대로 반환
  },
  async (error) => {
    const { config, response } = error;
    //console.log("인터셉터 작동: error response"+response)
    if (response && response.status === 401) {
      const errorCode = response.data.errorCode;
      if (errorCode === 10001) {
        try {
          // 토큰 재발급 요청
          // API 인스턴스를 그대로 사용하면 interceptor가 중첩된다.
          // 그렇게 되면 refresh token 까지 만료인 경우, alert 가 2번 발생한다.
          const tokenResponse = await axios.create({
            baseURL: API_BASE_URL,
            timeout: 2000,
            headers,
            withCredentials: true,
          }).post('/member/reissue');

          // 새로운 토큰 저장
          const newAccessToken = tokenResponse.data.token;
          localStorage.setItem(ACCESS_TOKEN, newAccessToken);

          // 기존 요청에 새 토큰을 설정
          config.headers.Authorization = `Bearer ${newAccessToken}`;

          // 기존 요청을 새 토큰과 함께 재시도
          return axios(config);
        } catch (refreshError) {
          //refresh token도 만료된 경우
          alert('인증 정보가 만료되었습니다. 다시 로그인해 주세요. 10001');
          localStorage.removeItem(ACCESS_TOKEN);
          window.location.href = '/login';
          return Promise.reject(refreshError);
        }
      } else if (errorCode === 10002) {
        // 재로그인 안내
        alert('인증 정보가 만료되었습니다. 다시 로그인해 주세요. 10002');
        localStorage.removeItem(ACCESS_TOKEN);
        window.location.href = '/login';
      } else {
        // 그 외의 401 오류 처리
        alert('인증 오류가 발생했습니다. 다시 시도해 주세요.');
        localStorage.removeItem(ACCESS_TOKEN);
        window.location.href = '/login';
      }
    }

    // 401 제외 다른 오류 처리
    // 다른 상태코드는 각각의 요청에서 처리한다.
    return Promise.reject(error);
  }
);

  return API;
}

// 사용 예시:
// const api = createAPI();
// api.get('/path').then(response => {
//   console.log(response.data);
// });
//ec2에 업로드시 URL 변경 필수

export default createAPI;