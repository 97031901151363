import React, { useEffect } from 'react';
import createAPI from '../service/ApiService';
import { useNavigate } from 'react-router-dom';

const CreateRoom = () => {
    
  const navigate = useNavigate();

  useEffect(() => {
    const accessToken = localStorage.getItem("ACCESS_TOKEN");

    if (!accessToken) {
      alert("로그인 후 이용해주세요.");
      navigate('/login');
    }
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    
    const data = new FormData(event.target);
    const roomName = data.get("roomName");
    const roomDetail = data.get("roomDetail");

    console.log('roomName:', roomName);
    console.log('roomDetail:', roomDetail);

    try {
      // createAPI를 사용하여 로그인 API 호출
      await createAPI().post('/room/create', { roomName, roomDetail });
      navigate('/');
    } catch (error) {
      if (error.response && error.response.status !== 401) {
        alert(error.message);
      }
    }
  };

  return (
    <div>
      <h2>방생성</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="roomName">방이름:</label>
          <input
            type="text"
            id="roomName"
            name="roomName"
            required
          />
        </div>
        <div>
          <label htmlFor="roomDetail">방 설명:</label>
          <input
            type="text"
            id="roomDetail"
            name="roomDetail"
            required
          />
        </div>
        <button type="submit">생성</button>
      </form>
    </div>
  );
}

export default CreateRoom;
